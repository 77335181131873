<template>
    <div class="home">
      <!-- <img alt="Vue logo" src="../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/> -->
     
  
  
  
      <!-- title -->
      <titles></titles>
      <!-- side -->
      <broadside></broadside>
      <!-- banner -->
      <div class="banner">
        <img src="@/assets/banner.jpg" />
      </div>
      <div class="index_serve_box">
        <div class="index_serve_nav_box">
          <!-- 如果需要使用分页器 -->
          <div class="swiper-pagination"></div>
        </div>
        <div class="swiper_box">
          <div class="all_box">
            <div class="swiper-container" style="width: 100%; height: 520px">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in swiperList">
                  <div class="swiper_item_box">
                    <div class="swiper_item_left">
                      <img :src="item.img" />
                      <div class="swiper_left_text">
                        <p>More than {{ currentYear }} years of cultivations ,BangMang has been  the leading</p>
                        <p>domestic service outsourcing brand name.</p>
                      </div>
                    </div>
                    <div class="swiper_item_right">
                      <div class="swiper_ljzx">Consult now</div>
                      <div class="swiper_text">
                        {{ item.text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要导航按钮 -->
  
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
  
      <!-- About -->
      <div class="about">
        <div class="all_box">
          <div class="about_box">
            <div class="about_left">
              <h3>ABOUT<br />Bangmang</h3>
              <h4>关于邦芒</h4>
              <div class="henxian"></div>
              <p>坚持专注于企业与人才服务</p>
            </div>
            <div class="about_right">
              <aboutText></aboutText>
            </div>
          </div>
        </div>
      </div>
      <!-- friend -->
      <div class="friend_box">
        <img src="@/assets/friend.png" />
      </div>
  
      <!-- check -->
  
      <check :type="2" @gotoContact="gotoContact($event)"></check>
      <foots></foots>
    </div>
  </template>
  
  <script>
  //导入图片
  import serveImg1 from "@/assets/serve01.png";
  import serveImg2 from "@/assets/serve02.png";
  import serveImg3 from "@/assets/serve03.png";
  import serveImg4 from "@/assets/serve04.png";
  import serveImg5 from "@/assets/serve05.png";
  import serveImg6 from "@/assets/serve06.png";
  
  
  import broadside from "./broadside.vue"
  import Swiper from "swiper";
  import check from "@/components/en/check_en.vue"
  import aboutText from "@/components/en/aboutText_en.vue";
  import titles from "@/components/en/titles_en.vue";
  import foots from "@/components/en/foots_en.vue";
  
  
  export default {
    name: "Home",
    components: {
      titles,
      foots,
      aboutText,
      check,
      broadside,
    },
    data() {
      return {
        currentYear: new Date().getFullYear() - 2007,
        broadsideDetailVisible: false,
        swiperList: [
          {
            title: "Personnel service outsourcing",
            img: serveImg6,
            text: "HR Solutions are integrated solutions that provide payroll, time and attendance, workers’ compensation, benefits, and risk mitigation. Delivered through an HR team and Professionals to help manage your compliance.",
          },
          {
            title: "Business process outsourcing",
            img: serveImg5,
            text: "Business Process Outsourcing services take charge of your operations, enabling streamlined business processes and coupled with deep industry insights, you will have the freedom to focus on own core competence and value-drivers, thereby improving customer experience and elevate business results",
          },
          {
            title: "Recruitment service outsourcing",
            img: serveImg4,
            text: "We know that you need qualified candidates who match your business, and the right people that can help your business succeed. Woxforce, customizes innovative solutions to free you from HR complexities, gives you a competitive edge and powers your company’s success",
          },
          {
            title: "Labour dispatch",
            img: serveImg3,
            text: 'As an institution with qualifications for labor dispatch, Bangmang enters into labor contracts with workers, sends workers to the labor enterprise, and then the labor enterprise to pay the service fees to the bang-mang. The service dispatch service can effectively help enterprises reduce labor disputes, reduce the risk of employers, autonomous and flexible workers, and focus on core business development.',
          },
          {
            title: "Short-term employment",
            img: serveImg2,
            text: "Bangmang provides recruitment, management, performance assessment, and return of personnel replacement services for the short- and medium-term vacant positions of enterprises, so as to help enterprises achieve more reasonable and effective human resources allocation.",
          },
          {
            title: "HR SaaS",
            img: serveImg1,
            text: "The technical support products provided by BMC include: HRO SaaS system, OA system, ERP system, payroll system, wage bar system, labor check system, tax declaration system, etc. Products are designed to link the business end and personal side of the enterprise, effectively improve the operating efficiency of the enterprise, so that the operation of enterprises is more informational, digitalized and intelligent",
          },
        ],
  
      };
    },
  
    mounted() {
  
      this.initSwiper();
    },
    methods: {
      //跳转到contact 并查询
      gotoContact(e) {
        this.$router.push({
          path: '/contact',
          query: {
            sCode: e.sCode,
            qCode: e.qCode,
            compname: e.compname
          }
        })
      },
      initSwiper() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(".swiper-container", {
            loop: true,
  
            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                let titleName;
                if (index == 0) {
                  titleName = "Personnel service outsourcing";
                }
                if (index == 1) {
                  titleName = "Business process outsourcing";
                }
                if (index == 2) {
                  titleName = "Recruitment service outsourcing";
                }
                if (index == 3) {
                  titleName = "Labour dispatch";
                }
                if (index == 4) {
                  titleName = "Short-term employment";
                }
                if (index == 5) {
                  titleName = "HR SaaS";
                }
                return '<span class="' + className + '">' + titleName + "</span>";
              },
            },
  
            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
          console.log(mySwiper);
        });
      },
  
    },
  };
  </script>
  
  
  <style lang="scss">
  .index_serve_nav_box {
    .swiper-pagination {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      top: 0;
      height: 75px;
      line-height: 75px;
    }
  
    .swiper-pagination-bullet {
      width: auto;
      height: auto;
      background: none;
      margin: 0 48px;
      font-size: 16px;
      color: #696969;
      opacity: 1;
    }
  
    .swiper-pagination-bullet-active {
      font-weight: bold;
      position: relative;
    }
  
    .swiper-pagination-bullet-active:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #d70035;
  
    }
  }
  
  .swiper_box {
    .swiper-button-prev:after {
      content: "";
      width: 26px;
      height: 19px;
      background: url(../assets/serve_left.png) center center no-repeat;
    }
  
    .swiper-button-next:after {
      content: "";
      width: 56px;
      height: 18px;
      background: url(../assets/serve_right.png) center center no-repeat;
    }
  
    .swiper-button-prev {
      left: auto;
      right: 60px;
      width: 26px;
      top: 80%;
    }
  
    .swiper-button-next {
      left: auto;
      right: -40px;
      width: 56px;
      top: 80%;
    }
  }
  
  .banner {
    width: 100%;
  
    img {
      width: 100%;
      display: block;
    }
  }
  
  .message_box {}
  
  body {
    margin: 0;
  }
  
  .all_box {
    width: 1300px;
    margin: 0 auto;
    position: relative;
  }
  
  .check_select {
    .el-input__wrapper {
      background: rgba(255, 255, 255, 0.3);
      height: 50px;
      box-shadow: none;
      padding: 0 20px;
    }
  }
  </style>
  
  <style lang="scss" scoped>
  .about {
    padding: 130px 0 115px;
  }
  
  .swiper_ljzx {
    display: inline-block;
    text-align: center;
    width: 188px;
    height: 46px;
    border: 2px solid #d70035;
    line-height: 46px;
    color: #d70035;
  }
  
  .swiper_text {
    text-align: left;
    font-size: 16px;
    color: #656565;
    line-height: 36px;
    text-indent: 2em;
    margin-top: 70px;
  }
  
  .swiper_left_text {
    text-align: left;
    font-size: 18px;
    color: #959595;
    padding-left: 55px;
    margin-top: 32px;
  
    p {
      margin: 0;
      margin: 5px 0;
    }
  }
  
  .swiper_item_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #fff;
    height: 100%;
  }
  
  .swiper_item_right {
    box-sizing: border-box;
    padding-right: 60px;
    padding-top: 60px;
    width: 30%;
    text-align: right;
  }
  
  .swiper_box {
    padding-bottom: 60px;
    padding-top: 45px;
    background: #f2f2f2;
    position: relative;
  }
  
  .index_serve_nav_box {
    position: relative;
    width: 100%;
    height: 75px;
    border-bottom: 3px solid #e0e0e0;
  }
  
  
  
  .about_left {
    text-align: left;
  
    h3 {
      font-size: 52px;
      color: #5e5e5e;
      font-weight: bold;
      opacity: 0.28;
      margin: 0;
      margin-bottom: 35px;
      text-transform: uppercase;
    }
  
    .henxian {
      width: 127px;
      height: 3px;
      background: #c5a97b;
      margin-bottom: 30px;
    }
  
    h4 {
      font-size: 55px;
      color: #5e5e5e;
      font-weight: bold;
      margin: 0;
      margin-bottom: 70px;
    }
  
    p {
      font-size: 18px;
      color: #666666;
      margin: 0;
    }
  }
  
  .about_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .about_right {
      width: 70%;
    }
  }
  
  .friend_box {
    width: 100%;
  
    img {
      width: 100%;
    }
  }
  </style>